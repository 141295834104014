import React, { useEffect, useState } from 'react'
import { Radios, Autocomplete } from 'mui-rff'
import { Button, Box, Grid } from '@mui/material'
import { Form, useField, useForm } from 'react-final-form'
import { useSnackbar } from 'notistack'
import { declined, status } from '../../constant/status'
import { useNavigate } from 'react-router-dom'
import { usePatchApplication } from '../../api/application'

export const ReviewForm = (props) => {
  const { data } = props
  const navigate = useNavigate()
  const { mutateAsync, isLoading } = usePatchApplication()
  const [value, setValue] = useState('')

  let statusArray = []
  Object.keys(status).map((each) => {
    statusArray.push({ value: status[each], label: status[each] })
  })

  const { enqueueSnackbar } = useSnackbar()
  const onSubmit = async (values) => {
    const res = await mutateAsync({
      ...values,
      applicationId: data?._id,
    }).catch((e) => {
      const str = e?.response?.data?.message
      enqueueSnackbar(typeof str === 'string' ? str : 'Failed to submit', {
        variant: 'error',
      })
    })

    if (res?.status === 200) {
      enqueueSnackbar(`Submitted`, {
        variant: 'success',
      })
      navigate('/admin')
    }
  }

  const declinedMsgPreset = [
    `Your submission of HT Eco Battery Sdn Bhd's Touch 'n Go redemption of RM 50 has been rejected. The details of proof of purchase / battery photo are not readable or visible.`,
    `Your submission of HT Eco Battery Sdn Bhd's Touch 'n Go redemption of RM 50 has been rejected. It is fully redeemed in this month.`,
    `Your submission of HT Eco Battery Sdn Bhd's Touch 'n Go redemption of RM 50 has been rejected. It is not a VARTA AGM from HT Eco Battery's authorised dealers.`,
    `Your submission of HT Eco Battery Sdn Bhd's Touch 'n Go redemption of RM 50 has been rejected. Please attach the correct invoice.`,
    `Your submission of HT Eco Battery Sdn Bhd's Touch 'n Go redemption of RM 50 has been rejected. Please attach a photo showing the serial number and that the battery has been installed in the car.`,
    `Your submission of HT Eco Battery Sdn Bhd's Touch 'n Go redemption of RM 50 has been rejected. Please make sure the serial number you entered matches the one shown in the photo.`,
    `Your submission of HT Eco Battery Sdn Bhd's Touch 'n Go redemption of RM 50 has been rejected. It is not an eligible product.`,
  ]

  const validate = (values) => {
    const errors = {}

    if (value.status === 'Declined') {
      if (!values.declinedMsg) {
        errors.declinedMsg = 'Required'
      }
    }

    return errors
  }

  const formFields = [
    {
      size: 12,
      field: <Radios label='Status' name='status' required={true} data={statusArray} />,
    },
    {
      size: 12,
      when: 'status',
      is: 'Declined',
      field: (
        <Autocomplete
          label='Declined SMS Message'
          name='declinedMsg'
          type='text'
          margin='none'
          freeSolo
          autoSelect
          selectOnFocus
          options={declinedMsgPreset}
          textFieldProps={{
            variant: 'outlined',
            multiline: true,
          }}
        />
      ),
    },
  ]
  return (
    <Box m={5}>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          status: data?.status ?? 'Pending',
          declinedMsg: declinedMsgPreset?.[0],
        }}
        validate={validate}
        render={({ handleSubmit, submitting, values }) => (
          <FormComponent
            handleSubmit={handleSubmit}
            submitting={submitting}
            formFields={formFields}
            values={values}
            data={data}
            isLoading={isLoading}
            declinedMsgPreset={declinedMsgPreset}
          />
        )}
      />
    </Box>
  )
}

const FormComponent = ({
  handleSubmit,
  submitting,
  formFields,
  values,
  isLoading,
  data,
  declinedMsgPreset,
}) => {
  const { change } = useForm()

  const { status } = values

  useEffect(() => {
    switch (status) {
      case declined:
        change('declinedMsg', declinedMsgPreset?.[0])
        break
      default:
        change('declinedMsg', undefined)
        break
    }
  }, [status])

  return (
    <form onSubmit={handleSubmit}>
      <Box display='flex' flexDirection='column' justifyContent='space-between' maxWidth={500}>
        {formFields.filter(Boolean).map((item, idx) => {
          return <FieldItem item={item} key={idx} />
        })}
        <Box my={3}>
          <Button
            variant='contained'
            type='submit'
            disabled={submitting || isLoading}
            style={{ backgroundColor: 'black', color: 'white' }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </form>
  )
}

const FieldItem = ({ item }) => {
  const {
    input: { value },
  } = useField(item.when, { subscription: { value: true } })
  if (!(item.when || item.is) || item?.is?.includes(value)) {
    return (
      <Grid item xs={12} sm={item.size}>
        {item.field}
      </Grid>
    )
  }
  return null
}

import React, { useState, useEffect, useCallback } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Paper,
  TablePagination,
  TableFooter,
  IconButton,
  Skeleton,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import { useTheme } from '@mui/material/styles'
import { debounce } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import DownloadIcon from '@mui/icons-material/Download'
import { useGetApplications, useExportApplications } from '../../api/application'
import { Link } from 'react-router-dom'

const ApplicationRow = ({ row }) => {
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell scope='row'>{row.name}</TableCell>
      <TableCell align='right'>{row.phone}</TableCell>
      <TableCell align='right'>{row.state}</TableCell>
      <TableCell align='right'>{row.brand}</TableCell>
      <TableCell align='right'>{row.serialNumber}</TableCell>
      <TableCell align='right'>{row.status}</TableCell>
      <TableCell align='right'>
        <Link to={`/admin/review-application?id=${row._id}`} style={{ textDecoration: 'none' }}>
          <Button variant={row?.isCompleted ? 'outlined' : 'contained'} size='small'>
            {row?.isCompleted ? 'View' : 'Review'}
          </Button>
        </Link>
      </TableCell>
    </TableRow>
  )
}

const ApplicationTable = () => {
  const navigate = useNavigate()

  const exportApi = useExportApplications()
  const { enqueueSnackbar } = useSnackbar()

  const [searchParams] = useSearchParams()
  const pageParam = Number(searchParams.get('page'))
  const limitParam = Number(searchParams.get('limit'))
  useEffect(() => {
    if (Number.isInteger(pageParam)) {
      setPage(pageParam)
    }
    if (limitParam) {
      setLimit(limitParam)
    }
  }, [limitParam, pageParam])

  const [page, setPage] = useState(Number.isInteger(pageParam) ? pageParam : 0)
  const [limit, setLimit] = useState(limitParam ? limitParam : 10)

  const { data, isLoading, refetch } = useGetApplications({
    page: page + 1,
    limit: limit,
  })

  const applicationData = data?.docs ?? []

  const debounceRefetch = useCallback(
    debounce(() => {
      refetch()
    }, 500),
    [refetch]
  )
  useEffect(() => {
    debounceRefetch()
    window.scrollTo(0, 0)
  }, [limit, page])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    navigate(`?page=${newPage}&limit=${limit}`)
  }

  const handleChangeRowsPerPage = (event) => {
    if (limit) {
      setLimit(parseInt(event.target.value, 10))
      setPage(0)
      navigate(`?page=0&limit=${event.target.value}`)
    }
  }

  const handleExport = async () => {
    const res = await exportApi.mutateAsync().catch((e) => {
      enqueueSnackbar('Failed to export data', {
        variant: 'error',
      })
    })
    const url = URL.createObjectURL(res.data)

    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `battery_campaign_2025.xlsx`)
    document.body.appendChild(link)
    link.click()

    if (res?.status === 200) {
      enqueueSnackbar(`Exported`, {
        variant: 'success',
      })
    }

    return res
  }

  return (
    <Box>
      <Box mb={3} display='flex' justifyContent='center'>
        <Typography variant='h2' style={{ fontSize: '22px' }}>
          Battery Campaign
        </Typography>
      </Box>
      <Box display='flex' justifyContent='flex-end'>
        <Button
          startIcon={<DownloadIcon />}
          endIcon={exportApi.isLoading && <CircularProgress size='1.5rem' />}
          onClick={handleExport}
          disabled={exportApi.isLoading}
        >
          Export Data
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align='right'>Phone</TableCell>
              <TableCell align='right'>State</TableCell>
              <TableCell align='right'>Brand</TableCell>
              <TableCell align='right'>Serial Number</TableCell>
              <TableCell align='right'>Status</TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading
              ? Array(10)
                  .fill()
                  .map((_, idx) => <SkeletonRow key={idx} height={30} />)
              : [...(applicationData ?? [])].map((row, idx) => (
                  <ApplicationRow key={idx} row={row} />
                ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                colSpan={10}
                count={data?.totalDocs ?? 0}
                rowsPerPage={limit}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  )
}

const SkeletonRow = React.memo(({ height }) => (
  <TableRow>
    <TableCell colSpan='100%' height={height}>
      <Skeleton width='100%' height='100%' />
    </TableCell>
  </TableRow>
))

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
  const theme = useTheme()

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

export default ApplicationTable
